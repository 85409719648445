<template>
  <v-container fluid>
    <v-row>
      <v-form ref="form" @submit.prevent="validateBeforeSubmit">
        <h4>Who is the provider's primary contact for this incident or allegation?</h4>

        <v-col cols="12">
          <v-text-field
            v-model="vm.incidentTitle"
            label="Incident Title"
            v-validate="'required'"
            data-vv-as="Incident Title"
            name="incidentTitle"
            :error-messages="errors.collect('incidentTitle')"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-select
            v-model="vm.incidentNotificationType"
            :items="incidentNotificationType"
            label="Incident Notification Type"
            v-validate="'required'"
            data-vv-as="incidentNotificationType"
            name="incidentNotificationType"
            @change="changeIncidentNotificationType"
            :error-messages="errors.collect('incidentNotificationType')"
          ></v-select>
        </v-col>

        <v-col cols="12">
          <v-radio-group
            v-model="vm.hasImpactedPersonDeathOccured"
            label="Has death of the impacted person occurred?"
            v-validate="'required'"
            data-vv-as="Has death of the impacted person occurred"
            name="hasImpactedPersonDeathOccured"
            :error-messages="errors.collect('hasImpactedPersonDeathOccured')"
          >
            <v-radio label="Yes" v-bind:value="true"></v-radio>
            <v-radio label="No" v-bind:value="false"></v-radio>
          </v-radio-group>
        </v-col>

        <v-col cols="12">
          <v-radio-group
            v-model="vm.allegationSubjects"
            label="Is there a subject or subjects of allegation for this incident?"
            v-validate="'required'"
            data-vv-as="Is there a subject or subjects of allegation for this incident"
            name="allegationSubjects"
            :error-messages="errors.collect('allegationSubjects')"
          >
            <v-radio label="Yes" :value="true"></v-radio>
            <v-radio label="No" :value="false"></v-radio>
          </v-radio-group>
        </v-col>

        <v-col cols="12">
          <v-radio-group
            v-model="vm.hasDeathOccuredOfAllegationOfPersonWithDisability"
            label="Has death of a person with disability who is the subject of allegation occurred?"
            v-validate="'required'"
            data-vv-as="Has death of person with disability as subject of allegation occured"
            name="hasDeathOccuredOfAllegationOfPersonWithDisability"
            :error-messages="errors.collect('hasDeathOccuredOfAllegationOfPersonWithDisability')"
          >
            <v-radio label="Yes" v-bind:value="true"></v-radio>
            <v-radio label="No" v-bind:value="false"></v-radio>
          </v-radio-group>
        </v-col>

        <v-btn color="primary" type="submit">Continue</v-btn>
      </v-form>
    </v-row>
  </v-container>
</template>


<script>
import Vue from "vue";
import VeeValidate from "vee-validate";

Vue.use(VeeValidate);

export default {
  name: "incident-overview",

  data: () => ({
    vm: [],
    incidentNotificationType: ["Immediate Notification", "5 Day Notification"]
  }),
  props: {
    msg: String
  },
  mounted() {
    this.init();
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    currentOrganisation() {
      return this.$store.getters.currentOrganisation;
    },
    currentCase() {
      return this.$store.getters.currentCase;
    },
    currentIncident() {
      return this.$store.getters.currentIncident;
    }
  },
  watch: {},
  methods: {
    init() {
      if (
        this.currentIncident == undefined ||
        this.currentIncident.incidentOverview == undefined
      ) {
        this.vm = {};
      } else {
        this.vm = this.currentIncident.incidentOverview;
        this.$emit(
          "update-TimeLine",
          this.currentIncident.incidentOverview.incidentNotificationType
        );
      }
    },

    validateBeforeSubmit() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.currentIncident.incidentOverview = this.vm;
          this.$store.dispatch("setCurrentIncident", this.currentIncident);
          this.next();
          return;
        } else {
          this.$store.dispatch("setToast", {
            message: "Form errors please correct before continuing",
            color: "red darken-4"
          });
        }
      });
    },

    next() {
      this.$emit("next-step");
    },
    changeIncidentNotificationType() {
      this.$emit("update-TimeLine", this.vm.incidentNotificationType);
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

