<template>
  <div style="display:flex" class="incident-overview">
    <div style="min-width:350px; margin-right:10px;">
      <v-timeline dense>
        <v-timeline-item
          right
          small
          v-bind:step="item.step"
          v-for="(item, i) in timeline"
          v-bind:key="i"
          v-bind:class="{ 'activetimeline-item': (selectedStep == item || item.children.indexOf(selectedStep)>=0) }"
        >
          <v-card class="timeline-card elevation-2 hvr-rectangle-out">
            <v-card-title
              class="subtitle-1 timeline-text"
              v-bind:class="{ 'selected': selectedStep == item }"
              @click="setSelectedStep(item,item)"
            >{{item.name}}</v-card-title>
            <v-card-text v-if="item.children.length>0">
              <ul v-for="(child, y) in item.children" v-bind:key="y">
                <li
                  @click="setSelectedStep(item,child)"
                  class="timeline-text"
                  v-bind:class="{ 'selected': selectedStep == child }"
                >{{child.name}}</li>
              </ul>
            </v-card-text>
          </v-card>
        </v-timeline-item>
      </v-timeline>
    </div>

    <div style="margin-right:20px;    width: 100%;">
      <v-card class="elevation-2" v-if="selectedStep!=null">
        <v-card-title class="subheading">{{selectedStep.name}}</v-card-title>
        <v-card-text>
          <component
            v-bind:is="selectedStep.component"
            v-on:next-step="next()"
            v-on:update-TimeLine="updateTimeLine"
          ></component>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>


<script>
import incidentOverview from "./incident-overview";
import providerDetails from "./provider-details";
import primaryContact from "./primary-contact";
import incidentCategory from "./incident-category";
import incidentDetails from "./incident-details";
import impactedPerson from "./impacted-person";
import immediateActionTaken from "./immediate-action-taken";
import riskAssessment from "./risk-assessment";
import incidentAttachments from "./incident-attachments";
import impactedPersonDetails from "./impacted-person-details";
import impactedPersonSupports from "./impacted-person-supports";
import witnesses from "./witnesses";
import subjectsOfAllegationWorker from "./subjects-of-allegation-worker";
import subjectsOfAllegationDisability from "./subjects-of-allegation-disability";
import subjectsOfAllegationOther from "./subjects-of-allegation-other";
import deathOfPersonWithDisability from "./death-of-person-with-disability";
import unauthorisedRestrictivePractice from "./unauthorised-restrictive-practice";

export default {
  name: "mainForm",
  components: {
    "incident-overview": incidentOverview,
    "provider-details": providerDetails,
    "primary-contact": primaryContact,
    "incident-category": incidentCategory,
    "incident-details": incidentDetails,
    "impacted-person": impactedPerson,
    "immediate-action-taken": immediateActionTaken,
    "risk-assessment": riskAssessment,
    "incident-attachments": incidentAttachments,
    "impacted-person-details": impactedPersonDetails,
    "impacted-person-supports": impactedPersonSupports,
    witnesses: witnesses,
    "subjects-of-allegation-worker": subjectsOfAllegationWorker,
    "subjects-of-allegation-disability": subjectsOfAllegationDisability,
    "subjects-of-allegation-other": subjectsOfAllegationOther,
    "death-of-person-with-disability": deathOfPersonWithDisability,
    "unauthorised-restrictive-practice": unauthorisedRestrictivePractice
  },
  data: () => ({
    selectedStep: null,
    parentStep: null,
    timeline: [
      {
        name: "Incident Overview",
        step: 1,
        component: "incident-overview",
        children: []
      }
    ],
    timeline5Day: [
      {
        name: "Incident Overview",
        step: 1,
        component: "incident-overview",
        children: []
      },
      {
        name: "Provider Details",
        step: 2,
        component: "provider-details",
        children: []
      },
      {
        name: "Primary Contact",
        step: 3,
        component: "primary-contact",
        children: []
      },
      {
        name: "Incident Category",
        step: 4,
        component: "incident-category",
        children: []
      },
      {
        name: "Incident Details",
        step: 5,
        component: "incident-details",
        children: []
      },
      {
        name: "Impacted Person",
        step: 6,
        component: "",
        children: [
          {
            name: "Impacted Person Details",
            component: "impacted-person-details",
            step: 6.2
          },
          {
            name: "Support Persons",
            component: "impacted-person-supports",
            step: 6.3
          }
        ]
      },
      {
        name: "Subjects of Allegation",
        step: 7,
        component: "",
        children: [
          {
            name: "Worker",
            component: "subjects-of-allegation-worker",
            step: 7.2
          },
          {
            name: "Person with Disability",
            component: "subjects-of-allegation-disability",
            step: 7.3
          },
          {
            name: "Other",
            component: "subjects-of-allegation-other",
            step: 7.4
          }
        ]
      },
      {
        name: "Witnesses",
        step: 8,
        component: "witnesses",
        children: []
      },
      {
        name: "Immediate Action Taken",
        step: 9,
        component: "immediate-action-taken",
        children: []
      },
      {
        name: "Risk Assessment",
        step: 10,
        component: "risk-assessment",
        children: []
      },
      {
        name: "Death of a person with Disability",
        step: 11,
        component: "death-of-person-with-disability",
        children: []
      },
      {
        name: "Unauthorised use of a restrictive practice",
        step: 12,
        component: "unauthorised-restrictive-practice",
        children: []
      },
      {
        name: "Attachments",
        step: 13,
        component: "incident-attachments",
        children: []
      }
    ],
    timelineImmediate: [
      {
        name: "Incident Overview",
        step: 1,
        component: "incident-overview",
        children: []
      },
      {
        name: "Provider Details",
        step: 2,
        component: "provider-details",
        children: []
      },
      {
        name: "Primary Contact",
        step: 3,
        component: "primary-contact",
        children: []
      },
      {
        name: "Incident Category",
        step: 4,
        component: "incident-category",
        children: []
      },
      {
        name: "Incident Details",
        step: 5,
        component: "incident-details",
        children: []
      },
      {
        name: "Impacted Person",
        step: 6,
        component: "impacted-person",
        children: []
      },
      {
        name: "Subjects of Allegation",
        step: 7,
        component: "",
        children: [
          {
            name: "Worker",
            component: "subjects-of-allegation-worker",
            step: 7.2
          },
          {
            name: "Person with Disability",
            component: "subjects-of-allegation-disability",
            step: 7.3
          },
          {
            name: "Other",
            component: "subjects-of-allegation-other",
            step: 7.4
          }
        ]
      },
      {
        name: "Immediate Action Taken",
        step: 8,
        component: "immediate-action-taken",
        children: []
      },
      {
        name: "Risk Assessment",
        step: 9,
        component: "risk-assessment",
        children: []
      },
      {
        name: "Attachments",
        step: 10,
        component: "incident-attachments",
        children: []
      }
    ]
  }),
  props: {
    msg: String
  },
  mounted() {
    this.init();
  },
  computed: {
    currentCase() {
      return this.$store.getters.currentCase;
    }
  },
  methods: {
    init() {
      this.setSelectedStep(this.timeline[0], this.timeline[0]);

      if (this.$route.query.step != undefined) {
        let parentStepNbr = this.$route.query.step;
        let childStepNbr = 0;
        const step = this.$route.query.step;
        if (step.indexOf(".") > -1) {
          parentStepNbr = parseInt(step.substring(0, step.indexOf(".")));
          childStepNbr = step;
        }

        this.parentStep = this.timeline.find(route => {
          return route.step == parentStepNbr;
        });
        if (step.indexOf(".") > -1) {
          this.selectedStep = this.parentStep.children.find(route => {
            return route.step == childStepNbr;
          });
        } else {
          this.selectedStep = this.parentStep;
        }
      }

      console.log(this.parentStep);
      this.$forceUpdate();
    },
    setSelectedStep(parent, child) {
      this.parentStep = parent;
      if (child != null) {
        this.selectedStep = child;
      }
    },
    updateTimeLine(incidentType) {
      if (incidentType == "5 Day Notification") {
        this.timeline = this.timeline5Day;
      } else if (incidentType == "Immediate Notification") {
        this.timeline = this.timelineImmediate;
      }
      this.$forceUpdate();
    },
    next() {
      if (
        this.parentStep == this.selectedStep &&
        this.parentStep.children.length > 0
      ) {
        this.selectedStep = this.parentStep.children[0];
        return;
      }
      if (this.parentStep == this.selectedStep) {
        this.parentStep = this.timeline[
          this.timeline.indexOf(this.parentStep) + 1
        ];
        if (this.parentStep.children.length > 0) {
          this.selectedStep = this.parentStep.children[0];
        } else {
          this.selectedStep = this.parentStep;
        }
        return;
      }

      //Set to next child of current parent  if exists
      var idx = this.parentStep.children.indexOf(this.selectedStep);
      if (idx >= 0 && idx + 1 < this.parentStep.children.length) {
        this.selectedStep = this.parentStep.children[idx + 1];
      }

      //move on to next parent
      if (idx >= 0 && idx + 1 >= this.parentStep.children.length) {
        this.parentStep = this.timeline[
          this.timeline.indexOf(this.parentStep) + 1
        ];
        if (this.parentStep.children.length > 0) {
          this.selectedStep = this.parentStep.children[0];
        } else {
          this.selectedStep = this.parentStep;
        }
      }
    },
    validate() {},
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.v-card__text {
  padding-top: 0px;
}
.v-timeline-item {
  padding-bottom: 10px;
}
.v-timeline {
  padding-top: 0px;
}

.title {
  margin-bottom: 10px;
}
.activetimeline-item .timeline-card {
  border-right: 4px solid #4caf50;
}

.selected {
  color: #4caf50;
  font-weight: bold;
}
.timeline-text {
  cursor: pointer;
}
.timeline-text:hover {
  color: #4caf50;
}
.participant-form {
  padding-top: 30px;
}
</style>

