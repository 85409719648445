<template>
  <v-container fluid>
   <v-form ref="form" @submit.prevent="validateBeforeSubmit">

<template v-if='this.currentIncident.incidentOverview.hasImpactedPersonDeathOccured == false'>
You answered 'No' to Death of Impacted Person in Incident Overview. There are no questions to answer here.
You can change your response in the overview if required.
</template>

        <template v-if='this.currentIncident.incidentOverview.hasImpactedPersonDeathOccured == true'>"
        <v-col cols="12">
          <v-select
            v-model="vm.swallowingBreathingChokingRisks"
            :items="swallowingBreathingChokingRisks"
            label="What swallowing, breathing or choking risks did the person have?"
            v-validate="'required'"
            data-vv-as="Swallowing, breathing or choking risks"
            name="swallowingBreathingChokingRisks"
            :error-messages="errors.collect('swallowingBreathingChokingRisks')"
          ></v-select>
        </v-col>

        <v-col cols="12">
           <v-select
            v-model="vm.lifestyleRisks"
            :items="lifestyleRisks"
            label="What lifestyle risks did the person have?"
            v-validate="'required'"
            data-vv-as="lifestyle risks"
            name="lifestyleRisks"
            :error-messages="errors.collect('lifestyleRisks')"
          ></v-select>
        </v-col>
        <v-col cols="12">
           <v-select
            v-model="vm.mobilityRestrictions"
            :items="mobilityRestrictions"
            label="What mobility restrictions did the person have?"
            v-validate="'required'"
            data-vv-as="mobility restrictions"
            name="mobilityRestrictions"
            :error-messages="errors.collect('mobilityRestrictions')"
          ></v-select>
        </v-col>
        <v-col cols="12">
           <v-select
            v-model="vm.otherHealthIssues"
            :items="otherHealthIssues"
            label="What other health issues did the person have?"
            v-validate="'required'"
            data-vv-as="other health issues"
            name="otherHealthIssues"
            :error-messages="errors.collect('otherHealthIssues')"
          ></v-select>
        </v-col>


 
      <v-col cols="12">
          <v-text-field
          type="number"
            v-model.number="vm.lastRecordedWeightBeforeDeath"
            label="Last recorded weight before death"
            v-validate="'required'"
            data-vv-as="Last recorded weight before death"
            name="lastRecordedWeightBeforeDeath"
            :error-messages="errors.collect('lastRecordedWeightBeforeDeath')"
          ></v-text-field>
        </v-col>
      
           <v-col cols="12">
          <v-text-field
          type="number"
            v-model.number="vm.lastRecordedHeightBeforeDeath"
            label="Last recorded height before death"
            v-validate="'required'"
            data-vv-as="Last recorded height before death"
            name="lastRecordedHeightBeforeDeath"
            :error-messages="errors.collect('lastRecordedHeightBeforeDeath')"
            
          ></v-text-field>
        </v-col>


          <v-col cols="12">
          <v-text-field
            v-model="vm.calculatedBMI"
            label="Calculated BMI"
            v-validate="'required'"
            data-vv-as="Calculated BMi"
            name="calculatedBMI"
            :error-messages="errors.collect('calculatedBMI')"
          ></v-text-field>
        </v-col>


<h4> List required here </h4>
  <v-btn @click="dialog=true" >Add medication</v-btn>

<h4> List required here </h4>
<v-btn @click="dialog=true" >Add additional medications</v-btn> (from last 12 months)




        <v-col cols="12">
          <v-textarea
            filled
            v-model="vm.medicationIncidentsLast12Months"
            label="Describe any medication incidents involving the person in the 12 months prior to their death"
            rows="5"
            data-vv-as="medication incidents 12 months prior"
            name="medicationIncidentsLast12Months"
            v-validate="'required'"
            :error-messages="errors.collect('medicationIncidentsLast12Months')"
          ></v-textarea>
        </v-col>

<h4> List required here </h4>
<v-btn @click="dialog=true" >Add immunisation</v-btn> List all up-to-date immunisations e.g. Influenza, pneumoccal, 


        <v-col cols="12">
          <v-textarea
            filled
            v-model="vm.lastOrRegularGPDetails"
            label="Provide details of the regular or last general practitioner the person visited. Include the GP's name, the practice name and the date of last visit"
            rows="5"
            data-vv-as="regular or last GP"
            name="lastOrRegularGPDetails"
            v-validate="'required'"
            :error-messages="errors.collect('lastOrRegularGPDetails')"
          ></v-textarea>
        </v-col>


        <v-col cols="12">
          <v-textarea
            filled
            v-model="vm.otherHealthPractitioners"
            label="Provide details of any other health practitioners the person visited. Include the practitioner's name, the practice name and when the date of their last visit was."
            rows="5"
            data-vv-as="other health practitioners"
            name="otherHealthPractitioners"
            v-validate="'required'"
            :error-messages="errors.collect('otherHealthPractitioners')"
          ></v-textarea>
        </v-col>


        <v-col cols="12">
          <v-textarea
            filled
            v-model="vm.releventIncidents12MonthsPrior"
            label="Describe any relevant incidents involving the person in the 12 months prior to death, e.g. chocking incidents, falls, unexplained injury."
            rows="5"
            data-vv-as="Relevent Incidents"
            name="releventIncidents12MonthsPrior"
            v-validate="'required'"
            :error-messages="errors.collect('releventIncidents12MonthsPrior')"
          ></v-textarea>
        </v-col>


      <v-col cols="12">
        <v-select
          v-model="vm.behavioursOfConcern"
          :items="behavioursOfConcern"
          label="Behaviours of Concern"
          v-validate="'required'"
          data-vv-as="Behaviours of Concern"
          name="behavioursOfConcern"
          :error-messages="errors.collect('behavioursOfConcern')"
        ></v-select>
      </v-col>

      <v-col cols="12" v-if="vm.behavioursOfConcern == 'Other'">
        <v-text-field
          v-model="vm.behavioursOfConcernOther"
          label="please specify other behaviours of concern"
          v-validate="'required'"
          data-vv-as="please specify other behaviours of concern"
          name="behavioursOfConcernOther"
          :error-messages="errors.collect('behavioursOfConcernOther')"
        ></v-text-field>
      </v-col>


        <v-col cols="12">
          <v-textarea
            filled
            v-model="vm.illnessLast12MonthsResultingInTreatment"
            label="Describe any illness the person had in the 12 months prior to their death which resulted in treatment by a doctor. Include Dates."
            rows="5"
            data-vv-as="Relevent Incidents"
            name="illnessLast12MonthsResultingInTreatment"
            v-validate="'required'"
            :error-messages="errors.collect('illnessLast12MonthsResultingInTreatment')"
          ></v-textarea>
        </v-col>




        <v-btn color="primary" type="submit">Continue</v-btn>
      
  

    </template>
    </v-form>
  </v-container>
</template>


<script>
import Vue from "vue";
import VeeValidate from "vee-validate";

Vue.use(VeeValidate);

export default {
  name: "incident-category",

  data: () => ({
    vm: [],
    swallowingBreathingChokingRisks: [
      "Asthma",
      "Recurring respiratory infections",
      "Chronic pulmonary disease / emphysema",
      "Gastroesophageal reflux",
      "Swollowing difficulties",
      "Help with meals",
      "Dental aid",
      "No teeth",
      "Some teeth",
      "Tube feeding",
      "Nil by mouth",
      "None"
    ],
    behavioursOfConcern: [
      "Food-related",
      "Eating non-food items",
      "Propert damage",
      "Physical aggresion",
      "Verbal aggresion",
      "Harm to self",
      "Unintentional self-risk",
      "Leaving premises without support",
      "Refusal to do things",
      "Repetitive or unusual habits",
      "Offending behaviour",
      "Sexually inappropriate behaviour",
      "Other"
    ],
    lifestyleRisks: [
      "Smoking",
      "Obesity",
      "Overweight",
      "Diabetes",
      "High Blood pressure",
      "None"
    ],
    mobilityRestrictions: [
      "Wheelchair",
      "Walking Frame",
      "Walking Stick",
      "Prosthesis",
      "None"
    ],
    otherHealthIssues: [
      "Cancer",
      "Epilepsy",
      "Osteoporosis",
      "Constipation",
      "Urinary incontinence",
      "Faecal incontinence",
      "None"
    ],
    date: new Date().toISOString().substr(0, 10),
    
  }),
  props: {
    msg: String
  },
  mounted() {
    this.init();
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    currentOrganisation() {
      return this.$store.getters.currentOrganisation;
    },
    currentCase() {
      return this.$store.getters.currentCase;
    },
    currentIncident() {
      return this.$store.getters.currentIncident;
    },
    
  },
  methods: {
    init() {
      this.vm =
        this.currentIncident == undefined ||
        this.currentIncident.deathOfPersonWithDisability == undefined
          ? {}
          : this.currentIncident.deathOfPersonWithDisability;
    },

    validateBeforeSubmit() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.currentIncident.deathOfPersonWithDisability = this.vm;
          this.$store.dispatch("setCurrentIncident", this.currentIncident);
          this.next();
          return;
        } else {
          this.$store.dispatch("setToast", {
            message: "Form errors please correct before continuing",
            color: "red darken-4"
          });
        }
      });
    },

    next() {
      this.$emit("next-step");
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

